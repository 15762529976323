document.addEventListener('DOMContentLoaded', () => {
  const animatedImgs = document.querySelectorAll(
    '.animatedImg, .is-style-rounded, .is-style-heart, .is-style-bubbles-left, .is-style-bubbles-right'
  );

  const handleIntersect = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('appear');
        entry.target.classList.remove('disappear');
      } else {
        entry.target.classList.remove('appear');
        entry.target.classList.add('disappear');
      }
    });
  };

  // ------------------ Create intersection observer
  const observer = new IntersectionObserver(handleIntersect, {
    root: null, // Use the viewport as the root
    rootMargin: '-300px 0px -50px 0px', // 0px for No margin
    threshold: 0.4, // % of the element is in the viewport
  });

  // ------------------ Observe the image
  animatedImgs.forEach((animatedImg) => {
    observer.observe(animatedImg);
  });
});
