import * as Focus from '../common/focus';

window.addEventListener('load', function () {
  const toggleSearchBtns = document.querySelectorAll('.toggleSearch');
  const searchModal = document.querySelector('#search-modal');
  const primaryNav = document.querySelector('.nav-primary-wrapper');

  function closeSearchModal() {
    searchModal.classList.remove('opened');
    toggleSearchBtns.forEach((toggleBtn) => {
      toggleBtn.setAttribute('aria-expanded', 'false');
    });

    if (searchModal.classList.contains('search-modal--window')) {
      Focus.untrapFocusInside(jQuery('#search-modal'));
      document.documentElement.classList.remove('lockedPosition');
    }

    if (searchModal.classList.contains('search-modal--bar')) {
      primaryNav.classList.remove('hidden');
    }
  }

  function openSearchModal() {
    const openEvent = new Event('opensearchmodal');
    searchModal.classList.add('opened');
    toggleSearchBtns.forEach((toggleBtn) => {
      toggleBtn.setAttribute('aria-expanded', 'true');
    });
    window.dispatchEvent(openEvent);

    if (searchModal.classList.contains('search-modal--window')) {
      Focus.trapFocusInside(jQuery('#search-modal'));
      document.documentElement.classList.add('lockedPosition');
    }

    if (searchModal.classList.contains('search-modal--bar')) {
      primaryNav.classList.add('hidden');
    }
  }

  if (0 < toggleSearchBtns.length) {
    toggleSearchBtns.forEach((toggleBtn) => {
      toggleBtn.addEventListener('click', () => {
        if (searchModal.classList.contains('opened')) {
          closeSearchModal();
        } else {
          openSearchModal();
        }
      });
    });
  }
});
