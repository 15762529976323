import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carousels = document.querySelectorAll('.recipes-carousel');

  if (0 < carousels.length) {
    carousels.forEach((carousel) => {
      const slides = carousel.querySelectorAll('.swiper-slide');
      const args = {
        centerInsufficientSlides: true,
        breakpoints: {
          slidesPerView: 1,
          spaceBetween: 20,
          640: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 80,
          },
          1400: {
            slidesPerView: 4,
            spaceBetween: 80,
          },
        },
        Mousewheel: true,
        navigation: {
          prevEl: carousel.querySelector('.swiper-button-prev'),
          nextEl: carousel.querySelector('.swiper-button-next'),
        },
        pagination: {
          el: carousel.querySelector('.swiper-pagination'),
          clickable: true,
        },
      };

      if (6 < slides.length) {
        args.centeredSlides = true;
        args.loop = true;
      } else {
        args.loop = false;
        args.watchOverflow = true;
      }

      new Swiper(carousel, args);
    });
  }
});
