import TomSelect from 'tom-select/dist/js/tom-select.complete.min.js';
let fieldsAreInitialized = false;

function changeFileUpload() {
  const multiFileUploads = document.querySelectorAll(
    '.gform_fileupload_multifile'
  );
  if (0 < multiFileUploads.length) {
    multiFileUploads.forEach(function (fileUpload) {
      const fileUploadParent = fileUpload.parentNode;
      fileUpload.querySelector('.gform_drop_instructions').textContent =
        wp.i18n._x(
          'Drag and drop file to upload',
          'drag and drop instruction',
          'vtx'
        );
      if (!fileUploadParent.querySelector('.icon-download')) {
        fileUpload
          .querySelector('.gform_drop_instructions')
          .insertAdjacentHTML(
            'beforebegin',
            '<span class="icon-download"></span>'
          );
      }
    });
  }
}

function changeFormLabel(label, input) {
  if (input) {
    if ('' !== input.value) {
      label.classList.add('active');
      input.classList.add('active');
    }

    label.addEventListener('click', function () {
      input.focus();
    });

    input.addEventListener('focus', function () {
      label.classList.add('active');
      input.classList.add('active');
    });

    input.addEventListener('focusout', function () {
      if (!input.value || '(___) ___-____' === input.value) {
        label.classList.remove('active');
        input.classList.remove('active');
      }
    });
  }
}

function addFormEvents() {
  const fields = document.querySelectorAll('.gfield');

  if (0 < fields.length) {
    fields.forEach(function (field) {
      if (field.classList.contains('initialized')) {
        return;
      }

      const classesExcluded = [
        'gfield--radio',
        'gfield--checkbox',
        'gfield--consent',
        'gfield--select',
      ];

      for (let i = 0; i < classesExcluded.length; i++) {
        if (field.classList.contains(classesExcluded[i])) {
          return;
        }
      }

      field.classList.add('initialized');

      const label = field.querySelector('.gfield_label');
      const input = field.querySelector(
        '.ginput_container input , .ginput_container textarea'
      );

      if (label && input) {
        changeFormLabel(label, input);
      }

      if (field.classList.contains('gfield--has-multiple-inputs')) {
        const subfields = field.querySelectorAll('.ginput_complex > span');

        if (0 < subfields.length) {
          subfields.forEach(function (subfield) {
            if (subfield.classList.contains('initialized')) {
              return;
            }
            subfield.classList.add('initialized');

            const sublabel = subfield.querySelector('label');
            const subinput = subfield.querySelector('input, textarea');
            changeFormLabel(sublabel, subinput);
          });
        }
      }
    });
  }
}

function customDropdownSelect() {
  const formDropdownFields = document.querySelectorAll('.gfield_select');

  if (0 < formDropdownFields.length) {
    formDropdownFields.forEach(function (dropdownField) {
      new TomSelect(dropdownField);
    });
  }
}

// -------------------- EXECUTION
// ------------ ON POST RENDER - I left the following in jquery because the equivalent in vanilla js does not work
jQuery(document).on('gform_post_render', function () {
  fieldsAreInitialized = true;
  changeFileUpload();
  addFormEvents();
});

// ------------ ON READY
window.addEventListener('load', function () {
  if (!fieldsAreInitialized) {
    changeFileUpload();
    customDropdownSelect();
    addFormEvents();
  }

  // ---------- Change Gravity FORM required field text
  const requiredLegend = document.querySelector(
    '.gform_wrapper .gform_required_legend'
  );

  if (requiredLegend) {
    requiredLegend.innerHTML = wp.i18n._x(
      '* Required fields',
      'gform required fields message',
      'vtx'
    );
  }

  // ------------------ When the form is submitted (To make it work, the AJAX must be enabled in the Gutenberg block Gravity)(Works only with jquery) :
  (function ($) {
    $(document).on('gform_confirmation_loaded', function () {
      // ------------------ Add a class to ba able to remove everything inside the entry content except the share block and gform_confirmation_wrapper
      const entryContent = document.querySelector('.entry-content');
      entryContent.classList.add('display-none');

      // ------------------ Add the share block
      const shareBlock = document.querySelector('.share-block');
      if (shareBlock) {
        shareBlock.classList.add('display-block');
      }

      addFormEvents();
    });
  })(jQuery);
});
